<template>
  <div class="app-container h">
    <div class="v no-flex" style="width: 320px; margin-right: 20px;">
      <div class="scroll-able-xy flex" style="border: 1px solid #DCDFE6; border-radius: 4px;">
        <el-tabs v-model="query.addressType" type="card" stretch style="width: 320px;">
          <el-tab-pane label="自定义区域" name="area">
            <dept-select v-model="query.treeNodeId" :type.sync="query.treeNodeType" v-if="query.addressType=='area'" @change="toQuery" />
          </el-tab-pane>
          <el-tab-pane label="标准行政区域" name="standard">
            <province-select v-model="query.treeNodeId" :type.sync="query.StandardAreaGroup" v-if="query.addressType=='standard'" @change="toQuery" />
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <div class="flex v">
      <el-form class="head-container e" label-position="top">
        <el-form-item label="时间范围" class="filter-item">
          <div class="h c">
            <el-date-picker v-model="query.begDate" type="date" value-format="timestamp" placeholder="选择开始日" :clearable="false" style="width:150px" />
            <div class="padding-0-05">至</div>
            <el-date-picker v-model="query.endDate" type="date" value-format="timestamp" placeholder="选择结束日" :clearable="false" style="width:150px" />
          </div>
        </el-form-item>
        <el-form-item label="商品范围" class="filter-item">
          <quick-select url="api/brand" v-model="query.brandIds" filterable clearable placeholder="请选择品牌" style="width: 250px;" multiple />
          <tree-picker v-model="query.seriesId" :params="{brandIds:query.brandIds}" url="api/series/tree" clearable placeholder="请选择系列" style="width: 150px;" v-if="query.brandIds" />
          <tree-picker v-model="query.categoryId" url="api/category/tree" clearable placeholder="请选择分类" style="width: 150px;" />
        </el-form-item>
        <el-form-item label="指定商品" class="filter-item">
          <el-tooltip content="点击选择商品" placement="bottom">
            <div slot="content" v-if="query.goods && query.goods.length" style="max-width: 320px;">
              <template v-for="(g, i) in query.goods">{{g.name}}{{i < query.goods.length - 1 ? "、" : ""}}</template>
            </div>
            <sku-selector request-url="api/goods/sku" button-type :button-text="queryGoodsName" @submit="handleGoodsSelect">
              <i class="el-icon-circle-close padding-0-10" @click.stop="query.goods = null" v-if="query.goods && query.goods.length" />
            </sku-selector>
          </el-tooltip>
        </el-form-item>
        <el-form-item label="汇总级别" class="filter-item">
          <el-select v-model="query.goodsGroup" placeholder style="width: 150px;">
            <el-option value="brandId" label="品牌结构" />
            <el-option value="seriesId" label="系列结构" />
            <el-option value="goodsCategoryId" label="分类结构" />
            <el-option value="spuId" label="商品结构" />
          </el-select>
        </el-form-item>
        <el-form-item label="排名依据" class="filter-item">
          <el-select v-model="query.saleValueGroup" placeholder style="width: 150px;">
            <el-option value="saleNumber" label="销售数量" />
            <el-option value="realSumMoney" label="销售金额" />
            <el-option value="sendNumber" label="发货数量" />
            <el-option value="sendMoney" label="发货金额" />
          </el-select>
        </el-form-item>
        <el-form-item class="filter-item">
          <el-button type="success" icon="el-icon-search" @click="toQuery" :loading="loading" :disabled="!searchable">统计</el-button>
          <el-button type="success" @click="toDownload" :disabled="!searchable" icon="el-icon-download" :loading="downloadLoading">导出</el-button>
        </el-form-item>
      </el-form>
      <div class="flex scroll-able">
        <el-collapse :value="['chart', 'data']">
          <el-collapse-item title="分析图表" name="chart">
            <div v-if="store && store.length">
              <form-info-item label="显示图表前：" label-width="auto" label-position="left" content-style="padding: 0;" class="filter-item">
                <el-input-number v-model="showRankNum" size="mini" :min="1" :max="1000" :step="1" :precision="0" controls-position="right" @change="draw" />&nbsp;名
              </form-info-item>
              <div>
                <el-row :gutter="20">
                  <el-col :span="12">
                    <div ref="gdBarChart"></div>
                  </el-col>
                  <el-col :span="12">
                    <div ref="gdPieChart"></div>
                  </el-col>
                </el-row>
              </div>
            </div>
            <div class="fc-g ta-c" v-if="!store || !store.length">没有分析数据，请更改统计分析条件或稍候重试。</div>
          </el-collapse-item>

          <el-collapse-item title="数据表格" name="data">
            <template v-if="store && store.length">
              <el-table v-loading="loading" row-key="id" :data="data" size="small" border height="530">
                <el-table-column prop="name" label="名称" width="120" show-overflow-tooltip />
                <el-table-column label="销售金额" align="center">
                  <el-table-column prop="realSumMoney" label="金额" :formatter="this.$price" min-width="100" />
                  <el-table-column prop="sumMoney" label="优惠前金额" :formatter="this.$price" min-width="100" />
                  <el-table-column prop="saleNumber" label="数量" min-width="100" />
                </el-table-column>
                <el-table-column label="发货金额" align="center">
                  <el-table-column prop="realSendMoney" label="金额" :formatter="this.$price" min-width="100" />
                  <el-table-column prop="sendMoney" label="优惠前金额" :formatter="this.$price" min-width="100" />
                  <el-table-column prop="sendNumber" label="数量" min-width="100" />
                </el-table-column>
              </el-table>
              <el-pagination align="center" :current-page.sync="page" :page-sizes="[5,10,20, 50]" :page-size.sync="size" layout="total, sizes, prev, pager, next, jumper" :total="store.length" />
            </template>
            <div class="fc-g ta-c" v-else>没有分析数据，请更改统计分析条件或稍候重试。</div>
          </el-collapse-item>
        </el-collapse>
      </div>
    </div>
  </div>
</template>

<script>
import deptSelect from "./deptSelect";
import provinceSelect from "./provinceSelect";
import skuSelector from "@/views/assembly/skuSelect";
import request from "@/utils/request";
import { download } from "@/api/data";
import { downloadFile } from "@/utils/index";
export default {
  name: "trend",
  components: {
    deptSelect,
    skuSelector,
    provinceSelect,
  },
  data() {
    let now = new Date();
    return {
      loading: false,
      goodsSpuName: null,
      store: [],
      storeTotal: 0,
      page: 1,
      size: 10,
      showRankNum: 5,
      downloadLoading: false,
      query: {
        addressType: "area",
        StandardAreaGroup: null,
        treeNodeId: null,
        treeNodeType: null,
        begDate: new Date(
          now.getFullYear(),
          now.getMonth() - 1,
          now.getDate()
        ).getTime(),
        endDate: new Date(
          now.getFullYear(),
          now.getMonth(),
          now.getDate(),
          23,
          59,
          59
        ).getTime(),
        saleValueGroup: "realSumMoney",
        goodsGroup: "brandId",
        goods: null,
        skuId: null,
        brandIds: null,
        seriesId: null,
        categoryId: null,
        page: 0,
        size: 10000,
      },
      chart: {
        gdBarChart: null,
        gdPieChart: null,
      },
    };
  },

  computed: {
    searchable() {
      return (
        this.query.begDate &&
        this.query.endDate &&
        this.query.saleValueGroup &&
        this.query.goodsGroup
      );
    },
    data() {
      return (this.store || []).slice(
        (this.page - 1) * this.size,
        this.page * this.size
      );
    },
    queryGoodsName() {
      if (this.query.goods && this.query.goods.length) {
        let str = this.query.goods[0].name;
        if (this.query.goods.length > 1)
          str += "等" + this.query.goods.length + "个商品";
        return str;
      }
      return "选择商品";
    },
  },
  methods: {
    handleGoodsSelect(res) {
      if (res) {
        this.query.goods = res;
      }
    },
    makeParams() {
      let data = JSON.parse(JSON.stringify(this.query));
      data.begDate = new Date(data.begDate);
      data.endDate = new Date(data.endDate);
      data.begDate = new Date(
        data.begDate.getFullYear(),
        data.begDate.getMonth(),
        data.begDate.getDate()
      ).getTime();
      data.endDate = new Date(
        data.endDate.getFullYear(),
        data.endDate.getMonth(),
        data.endDate.getDate() + 1
      ).getTime();
      if (this.query.addressType == "standard") {
        if (this.query.StandardAreaGroup == 0) {
          data.StandardAreaGroup = "country";
        }
        if (this.query.StandardAreaGroup == 1) {
          data.StandardAreaGroup = "province";
        }
        if (this.query.StandardAreaGroup == 2) {
          data.StandardAreaGroup = "city";
        }
        if (this.query.StandardAreaGroup == 3) {
          data.StandardAreaGroup = "district";
        }
        delete data.treeNodeType;
      } else {
        data.treeNodeType = this.query.treeNodeType;
        delete data.StandardAreaGroup;
      }

      if (data.goods && data.goods.length)
        data.skuIds = data.goods.map((o) => o.id);
      delete data.goods;
      return data;
    },
    toQuery() {
      let data = this.makeParams();
      this.loading = true;
      request({
        url: "@host:analysis;api/analy/purchase/sale/goods",
        method: "get",
        params: data,
      })
        .then((res) => {
          this.store = res.content;
          this.storeTotal = res.totalElements;
          this.$nextTick((_) => {
            this.draw();
          });
        })
        .catch((err) => {
          this.store = [];
        })
        .finally((_) => {
          this.loading = false;
        });
    },
    handleDelete(e) {
      if (!this.goodsSpuName) {
        this.query.skuId = null;
      }
    },
    draw() {
      this.drawGdBarChart();
      this.drawGdPieChart();
    },
    drawGdBarChart() {
      if (this.store && this.store.length > 0) {
        if (!this.chart.gdBarChart) {
          this.chart.gdBarChart = echarts.init(this.$refs.gdBarChart, "light", {
            height: 420,
          });
        }
        let xName = [];
        let xData = [];
        let yName = [];
        let svgp = [];
        let seriesData = [];
        if (
          this.query.saleValueGroup == "saleNumber" ||
          this.query.saleValueGroup == "checkSaleNumber" ||
          this.query.saleValueGroup == "sendNumber" ||
          this.query.saleValueGroup == "checkSendNumber"
        ) {
          yName.push("数量");
          (this.store || []).forEach((o) => {
            xData.push(o.name.length > 10 ? o.name.slice(0, 10) : o.name);
            seriesData.push(o[this.query.saleValueGroup]);
          });
        } else {
          yName.push("金额(万元)");
          (this.store || []).forEach((o) => {
            xData.push(o.name.length > 10 ? o.name.slice(0, 10) : o.name);
            seriesData.push(
              Math.round(o[this.query.saleValueGroup] * 0.000001 * 100) / 100
            );
          });
        }
        switch (this.query.goodsGroup) {
          case "brandId":
            xName.push("品牌结构");
            break;
          case "seriesId":
            xName.push("系列结构");
            break;
          case "goodsCategoryId":
            xName.push("分类结构");
            break;
          case "spuId":
            xName.push("商品结构");
            break;
        }
        switch (this.query.saleValueGroup) {
          case "realSumMoney":
            svgp.push("销售金额");
            break;
          case "realCheckSumMoney":
            svgp.push("已上报销售金额");
            break;
          case "saleNumber":
            svgp.push("销售数量");
            break;
          case "checkSaleNumber":
            svgp.push("已上报销售数量");
            break;
          case "sendMoney":
            svgp.push("发货金额");
            break;
          case "checkSendMoney":
            svgp.push("已上报发货金额");
            break;
          case "sendNumber":
            svgp.push("发货数量");
            break;
          case "checkSendNumber":
            svgp.push("已上报发货数量");
            break;
        }
        this.chart.gdBarChart.clear();
        this.chart.gdBarChart.setOption({
          title: {
            text: xName + svgp + "前" + this.showRankNum,
            left: "center",
          },
          color: ["#3398DB"],
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "shadow",
            },
          },

          grid: {
            y2: 150,
            left: "3%",
            right: "4%",
            bottom: "3%",
            containLabel: true,
          },
          xAxis: [
            {
              type: "category",
              data: xData.slice(0, this.showRankNum),
              axisLabel: {
                interval: 0,
                formatter: function (value) {
                  return value.split("").join("\n");
                },
              },
            },
          ],
          yAxis: [
            {
              type: "value",
              name: yName,
            },
          ],
          series: [
            {
              name: xName,
              type: "bar",
              data: seriesData.slice(0, this.showRankNum),
              itemStyle: {
                normal: {
                  label: {
                    show: true,
                    position: "top",
                    textStyle: {
                      color: "black",
                      fontSize: 12,
                    },
                  },
                },
              },
            },
          ],
        });
      } else {
        this.chart.gdBarChart = null;
      }
    },
    drawGdPieChart() {
      if (this.store && this.store.length > 0) {
        if (!this.chart.gdPieChart) {
          this.chart.gdPieChart = echarts.init(this.$refs.gdPieChart, "light", {
            height: 420,
          });
        }
        let xName = [];
        let svgp = [];
        let sgp = this.query.saleValueGroup;
        let newArray = this.store.map((o) => {
          if (
            sgp == "saleNumber" ||
            sgp == "checkSaleNumber" ||
            sgp == "sendNumber" ||
            sgp == "checkSendNumber"
          ) {
            return {
              name: o.name.length > 10 ? o.name.slice(0, 10) : o.name,
              value: o[sgp] == 0 ? null : o[sgp], //数据为0不显示出来
            };
          } else {
            return {
              name: o.name.length > 10 ? o.name.slice(0, 10) : o.name,
              value: o[sgp] == 0 ? null : Math.round(o[sgp] * 0.01 * 100) / 100,
            };
          }
        });
        switch (this.query.goodsGroup) {
          case "brandId":
            xName.push("品牌");
            break;
          case "seriesId":
            xName.push("系列");
            break;
          case "goodsCategoryId":
            xName.push("分类");
            break;
          case "spuId":
            xName.push("商品");
            break;
        }
        switch (this.query.saleValueGroup) {
          case "realSumMoney":
            svgp.push("销售金额");
            break;
          case "realCheckSumMoney":
            svgp.push("已上报销售金额");
            break;
          case "saleNumber":
            svgp.push("销售数量");
            break;
          case "checkSaleNumber":
            svgp.push("已上报销售数量");
            break;
          case "sendMoney":
            svgp.push("发货金额");
            break;
          case "checkSendMoney":
            svgp.push("已上报发货金额");
            break;
          case "sendNumber":
            svgp.push("发货数量");
            break;
          case "checkSendNumber":
            svgp.push("已上报发货数量");
            break;
        }

        this.chart.gdPieChart.clear();
        this.chart.gdPieChart.setOption({
          title: {
            text: xName + svgp + "汇总分析前" + this.showRankNum,
            left: "center",
          },
          tooltip: {
            trigger: "item",
            formatter: "{a} <br/>{b} : {c} ({d}%)",
          },
          series: [
            {
              name: xName + svgp,
              type: "pie",
              radius: "55%",
              center: ["50%", "60%"],
              data: newArray.slice(0, this.showRankNum),
              itemStyle: {
                normal: {
                  label: {
                    show: false,
                  },
                  labelLine: {
                    show: false,
                  },
                },
              },
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: "rgba(0, 0, 0, 0.5)",
                },
              },
            },
          ],
        });
      } else {
        this.chart.gdPieChart = null;
      }
    },
    toDownload() {
      let params = this.makeParams();
      this.downloadLoading = true;
      download(
        "@host:analysis;api/analy/purchase/download/analyShopGoods",
        params
      )
        .then((result) => {
          downloadFile(result, "采购商品结构", "xlsx");
          this.downloadLoading = false;
        })
        .catch((err) => {
          this.$message.error(err.message || "操作失败，请稍候再试。");
          this.downloadLoading = false;
        });
    },
  },
};
</script>

